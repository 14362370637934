/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2 } from '@beelineloans/cx-library/src/components/typography';
import TitleTemplate from '../Template';

const Table = styled.table`
	border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
`;
const THead = styled.thead``;
const TBody = styled.tbody``;
const Row = styled.tr``;
const Cell = styled.td`
	border: 1px solid black;
	border-collapse: collapse;
	padding: 5px;
`;
const HeadCell = styled.th`
	border: 1px solid black;
	border-collapse: collapse;
	padding: 5px;
`;

const BLTLLC = 'Beeline Title, LLC';
const BLSSLLC = 'Beeline Settlement Services, LLC';
const BLP = 'Partner';
const MAIN_NPN = 19465272;
const SECOND_NPN = 19764263;
const LIC_NOT_REQ = 'License not required';

const LicenseInformation = () => {
  const titleDetails = [
    { state: 'Alabama' },
    { state: 'Alaska' },
    { state: 'Arizona', dba: BLSSLLC, npn: SECOND_NPN, tln: '3001176688', resa: 'EA-1029427' },
    { state: 'Arkansas' },
    { state: 'California' },
    { state: 'Colorado', dba: BLTLLC, npn: MAIN_NPN, tln: '708231' },
    { state: 'Connecticut' },
    { state: 'Delaware', dba: BLTLLC, npn: MAIN_NPN, tln: '3001533468' },
    { state: 'District of Columbia', dba: BLTLLC, npn: MAIN_NPN, tln: '3000875864' },
    { state: 'Florida', dba: BLTLLC, npn: MAIN_NPN, tln: 'W716027' },
    { state: 'Georgia', dba: BLTLLC, npn: MAIN_NPN, tln: '218162' },
    { state: 'Hawaii' },
    { state: 'Idaho' },
    { state: 'Illinois' },
    { state: 'Indiana', dba: BLTLLC, npn: MAIN_NPN, tln: '3681170' },
    { state: 'Iowa' },
    { state: 'Kansas', dba: BLTLLC, npn: MAIN_NPN, tln: '384134617' },
    { state: 'Kentucky', dba: BLTLLC, tln: LIC_NOT_REQ },
    { state: 'Louisiana' },
    { state: 'Maine' },
    { state: 'Maryland', dba: BLSSLLC, npn: SECOND_NPN, tln: '3001279979' },
    { state: 'Massachusetts', dba: BLTLLC, tln: LIC_NOT_REQ },
    { state: 'Michigan', dba: BLTLLC, npn: MAIN_NPN, tln: '131029' },
    { state: 'Minnesota', dba: BLTLLC, npn: MAIN_NPN, tln: '40745156' },
    { state: 'Mississippi', dba: BLTLLC, npn: MAIN_NPN, tln: '15041828' },
    { state: 'Missouri' },
    { state: 'Montana' },
    { state: 'Nebraska' },
    { state: 'Nevada' },
    { state: 'New Hampshire' },
    { state: 'New Jersey', dba: BLTLLC, npn: MAIN_NPN, tln: '3001287444' },
    { state: 'New Mexico' },
    { state: 'New York' },
    { state: 'North Carolina', dba: BLTLLC, npn: MAIN_NPN, tln: '3001155111' },
    { state: 'North Dakota' },
    { state: 'Ohio' },
    { state: 'Oklahoma', dba: BLTLLC, npn: MAIN_NPN, tln: '3001556278' },
    { state: 'Oregon' },
    { state: 'Pennsylvania' },
    { state: 'Rhode Island', dba: BLTLLC, npn: MAIN_NPN, tln: '3000828142' },
    { state: 'South Carolina', dba: BLTLLC, npn: MAIN_NPN, tln: '3001155059' },
    { state: 'South Dakota' },
    { state: 'Tennessee', dba: BLTLLC, npn: MAIN_NPN, tln: '3001205893' },
    { state: 'Texas', dba: 'Beeline Texas Title, LLC', npn: '19557151', tln: '2535096' },
    { state: 'Utah' },
    { state: 'Vermont' },
    { state: 'Virginia', dba: BLTLLC, npn: MAIN_NPN, tln: '148341', resa: 'RESA148341' },
    { state: 'Washington' },
    { state: 'West Virginia' },
    { state: 'Wisconsin', dba: BLTLLC, tln: LIC_NOT_REQ }
  ];

  return (
    <TitleTemplate headline="Beeline Title - License Information">
      <SEO
        path={CONST.LINKS.TITLE.LICENSE}
        title="License Information"
        description="Beeline Title - License Information"
      />
      <UpdateAt date="May 2022" />
      <Container>
        <H2>License Information</H2>
        <Table>
          <THead>
            <Row>
              <HeadCell>State</HeadCell>
              <HeadCell>DBA</HeadCell>
              <HeadCell>NPN Number</HeadCell>
              <HeadCell>Title License Number</HeadCell>
              <HeadCell>Escrow/RESA</HeadCell>
            </Row>
          </THead>
          <TBody>
            {titleDetails.map(st => (
              <Row key={st.state}>
                <Cell>{st.state}</Cell>
                <Cell>{st.dba ?? BLP}</Cell>
                <Cell>{st.npn ?? '-'}</Cell>
                <Cell>{st.tln ?? '-'}</Cell>
                <Cell>{st.resa ?? '-'}</Cell>
              </Row>
            ))}
          </TBody>
        </Table>
      </Container>
    </TitleTemplate>
  );
};

export default LicenseInformation;
